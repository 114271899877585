import { Pipe, PipeTransform } from '@angular/core';
import { LawArticleStatus } from '../law-article-status';

@Pipe({
  name: 'lawArticleStatusLabel',
  standalone: true,
})
export class LawArticleStatusLabelPipe implements PipeTransform {
  private readonly labelsMap: Record<LawArticleStatus, string> = {
    [LawArticleStatus.Abrogated]: 'Abrogé',
    [LawArticleStatus.AbrogatedDiff]: 'Abrogé différé',
    [LawArticleStatus.Canceled]: 'Annulé',
    [LawArticleStatus.InEffect]: 'En vigueur',
    [LawArticleStatus.InEffectDiff]: 'Entrée en vigueur différé',
    [LawArticleStatus.Modified]: 'Modifié',
    [LawArticleStatus.ModifiedAborted]: 'Modifié, mort né',
    [LawArticleStatus.Obsolete]: 'Obsolète',
    [LawArticleStatus.Transfered]: 'Transféré',
  };

  transform(value: LawArticleStatus): string {
    return this.labelsMap[value];
  }
}
