export enum LawArticleStatus {
  InEffect = 'VIGUEUR',
  Abrogated = 'ABROGE',
  Modified = 'MODIFIE',
  Obsolete = 'PERIME',
  Canceled = 'ANNULE',
  Transfered = 'TRANSFERE',
  AbrogatedDiff = 'ABROGE_DIFF',
  InEffectDiff = 'VIGUEUR_DIFF',
  ModifiedAborted = 'MODIFIE_MORT_NE',
}
